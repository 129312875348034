export function printDate(value, locale = "es") {
  if (!value) return 0
  const d = new Date(value)
  const ye = new Intl.DateTimeFormat(locale, { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat(locale, { month: "long" }).format(d)
  const da = new Intl.DateTimeFormat(locale, { day: "2-digit" }).format(d)
  return `${da} ${mo}, ${ye}`
}

const monthList = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
]

export function currentMonth() {
  const d = new Date()
  return monthList[d.getMonth()]
}
