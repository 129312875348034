import styled from "styled-components"
import * as PropTypes from "prop-types"
import { printDate } from "@/helpers/formatter"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import LazyLoad from "react-lazyload"
import Typography from "@/styled/Typography"

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  height: 220px;
`

const Box = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media ${({ theme }) => theme.breakpoints.lg} {
    border-radius: 10px;
    margin: 0 5px;
    box-shadow: 0 2px 10px 0 ${({ theme }) => theme.colors.lightGray};
  }
`

const Content = styled.div`
  padding: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export default function Article(props) {
  const { post, page } = props
  const { title, description, cloudinaryImage, publishDate, slug } = post

  if (!cloudinaryImage) {
    console.warn(`Article without media: ${title}`)
    return null
  }

  const handleClick = () => {
    sendEvent("Click Blog post", { event_category: page, event_label: title })
  }

  return (
    <Box>
      <LazyLoad height={220}>
        <Image src={cloudinaryImage} alt={title} />
      </LazyLoad>
      <Content>
        <Typography variant="small" $weight="light" style={{ marginBottom: "25px" }}>
          {printDate(publishDate)}
        </Typography>
        <Typography el="h3" variant="title4" $weight="medium" style={{ marginBottom: "15px" }}>
          {title}
        </Typography>
        <Typography variant="small" $weight="light" dangerouslySetInnerHTML={{ __html: description }} />
        <Typography
          as="a"
          variant="small"
          $weight="light"
          $txtColor="skyBlue"
          href={`/blog/${slug}`}
          style={{ marginTop: "auto" }}
          onClick={handleClick}>
          Leer más
        </Typography>
      </Content>
    </Box>
  )
}

Article.propTypes = {
  post: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired
}
